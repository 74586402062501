import * as React from "react";
import { Component } from "react";
import { observer, Provider } from "mobx-react";
import { connexionStore } from "../store/connexionStore";
import { Login } from "../components/login";

interface State {
  loadedResources: boolean;
}
interface Props { }
@observer
export class ConnexionProvider extends Component<Props, State> {
  state = {
    loadedResources: false,
    ConnexionStore: connexionStore.getInstance()
  };

  componentDidMount() {
    this.setState({
      loadedResources: true
    });
  }

  render() {
    if (this.state.loadedResources) {
      return (
        <Provider ConnexionStore={this.state.ConnexionStore}>
          <Login />
        </Provider>
      );
    }
    return null;
  }
}