import { fireAuth } from '../../Firebase'
import { authenticatedUser } from '../model/authenticatedUser';
import { authResponse } from './authResponse';
export class authService {
  static user: authenticatedUser = new authenticatedUser();

  static signInWithEmailPwd = async (email: string, pwd: string) => {
    try {
      await fireAuth.signInWithEmailAndPassword(email, pwd);

    }
    catch (e) {
      switch (e.code) {
        case "auth/wrong-email": return authResponse.ERROR_INVALID_EMAIL;
        case "auth/wrong-password": return authResponse.ERROR_WRONG_PASSWORD;
        case "auth/user-not-found": return authResponse.ERROR_USER_NOT_FOUND;
        case "auth/operation-not-allowed": return authResponse.GENERAL_ERROR;
        default: return authResponse.GENERAL_ERROR;
      }
    }
  }

  static signOut = async () => {
    await fireAuth.signOut().then(function () {
      // Sign-out successful.
    }).catch(function (error: any) {
      // An error happened.
    });
  }

  static checkIfAuth = async () => {
    var user = fireAuth.currentUser;
    if (user) {
      return true;
    } else {
      return false;
    }
  }
}
