import { observer } from "mobx-react";
import * as React from "react";
import { Review } from "../model/review";
import '../review.css';
import { Redirect } from 'react-router-dom'
import { reviewStore } from "../store/reviewStore";
import { ReviewProvider } from "../provider/reviewProvider";
import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';

interface Props {
    Review?: Review;
}
@observer
export class ReviewFrame extends React.Component<Props>{

    review = this.props.Review;
    state = {
        isRedirected: false,
        redirectedLink: "/critique/" + this.review.Titre.split(' ').join(''),
        text: ""
    };

    handleClick = async () => {
        await reviewStore.getInstance().setSelectedReview(this.review);
        this.setState({
            isRedirected: true
        });
    }

    componentDidMount = () => {
        var jsonParsing = JSON.parse(this.review.Texte);
        var content = convertFromRaw(jsonParsing);
        var parsedText = convertToHTML(content);
        this.setState({
            text: parsedText.substring(0, 100)
        });
    }

    render() {
        if (this.review) {
            if (this.state.isRedirected) {
                return <Redirect push to={this.state.redirectedLink} component={ReviewProvider} />
            }
            else {
                return (
                    <React.Fragment>
                        <div className="flip-card" onClick={this.handleClick}>
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={this.review.Affiche} alt="" />
                                </div>
                                <div className="flip-card-back">
                                    <p>{this.review.Phrase}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
        return null;
    }
}