import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import './custom.css'
import { ConnexionProvider } from './connexion/provider/connexionProvider';
import { ReviewProvider } from './review/provider/reviewProvider';
import { ReviewsProvider } from './review/provider/reviewsProvider';
import { RedactionProvider } from './review/provider/redactionProvider';
import { AccueilProvider } from './accueilProvider';
import { About } from './about/components/about';
import { ControlPanelProvider } from './controlPanel/provider/controlPanelProvider';
import { Construction } from './construction';
import { Helmet } from "react-helmet";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{"Les Projections Intimes"}</title>
          <meta property="og:title" content={"Les Projections Intimes"} />
          <meta name="description" content={""} />
        </Helmet>
        <Route exact path='/' component={AccueilProvider} />
        <Route path='/archives' component={ReviewsProvider} />
        <Route path='/critique/:titreCritique' component={ReviewProvider} />
        <Route path='/a_propos' component={About} />
        <Route path='/config' component={ControlPanelProvider} />
        <Route path='/redaction' component={RedactionProvider} />
        <Route path='/connexion' component={ConnexionProvider} />
      </Layout>
    );
  }
}
