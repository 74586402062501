import * as React from "react";
import "../about.css"

export class About extends React.Component {

    render() {
        return (
            <div>
                <div className="aboutClass">
                    À propos
                </div>
                <div className="textIntro">
                    <img src={require('../img/imgIntro.jpg')} className="popcorn" alt="" />
                    <p>
                        <span>
                            Les films sont autant projet&eacute;s par le projecteur que par le spectateur.
                            Ils ne sont pas des pages blanches mais &agrave; travers le d&eacute;ploiement de leur histoire,
                            les films offrent un espace permettant de r&eacute;agir et de donner vie &agrave; nos &eacute;motions.
                            En effet, il existe plusieurs fa&ccedil;ons d&rsquo;entrer en contact avec soi-m&ecirc;me.
                            Certaines &eacute;tant plus mena&ccedil;antes que d&rsquo;autres.
                            L&rsquo;art est un m&eacute;dium doux et accessible qui nous offre une exp&eacute;rience profonde mais avec suffisamment de distance pour &ecirc;tre tol&eacute;rable.
                            On peut donc plus facilement se laisser bercer dans un univers sans se sentir submerg&eacute; par celui-ci.
                            Toutefois, il arrive souvent que des &oelig;uvres trouvent un raccourci &agrave; travers toutes nos d&eacute;fenses et viennent nous toucher profond&eacute;ment en suscitant larmes, remises en question, angoisses et autre.
                            Je vous propose d&rsquo;essayer de commencer &agrave; mettre des mots et &agrave; d&eacute;cortiquer certaines exp&eacute;riences que vous pouvez ressentir afin de vous aider &agrave; apprivoiser votre monde int&eacute;rieur et ce, &agrave; travers ces &oelig;uvres.
                            En esp&eacute;rant que cela vous donnera envie d&rsquo;aller plus loin dans ce processus, par une exp&eacute;rience unique &agrave; vous, en th&eacute;rapie.
                            Je me base sur mon propre v&eacute;cu, le tout filtr&eacute; par mes propres enjeux mais en ajoutant quelques connaissances acquises dans mon parcours comme psychologue.
                            J&rsquo;esp&egrave;re sinc&egrave;rement que ces mots se rendront &agrave; vous. Mais &agrave; vous, intimement.
                        </span>
                    </p>
                </div>
                <div className="bioContainer">
                    <div className="bioTitre">
                        Bio
                </div>
                    <div className="bio">
                        <p>
                            <span>
                                Sarah-Hélène Julien est psychologue depuis 2020. Elle est détentrice d’un doctorat en psychologie de l’Université de Montréal.
                                Travaillant désormais comme psychologue clinicienne en bureau privé, elle a été en contact avec une clientèle diversifiée.
                                À travers ses expériences de psychothérapie, elle constate que certaines réflexions et thématiques reviennent et semblent être partagées par plusieurs, malgré les différences individuelles frappantes.
                                Ces réflexions sont jugées essentielles par Dre Julien qui cherche toujours à les alimenter.
                                Toutefois, étant donné leur profondeur, ces réflexions demandent un investissement majeur de la part de chacun, qu’on ne prend pas toujours le temps de s’offrir.
                            </span>
                            <p>
                                <span>
                                    Passionnée par ces discussions, Dre Julien s’est donc demandé comment rejoindre un plus large éventail d’individus afin de leur permettre d’entamer ce processus.
                                    L’idée de lancer Les Projections Intimes naît donc de cet intérêt à comprendre et à explorer l’authenticité, l’angoisse, la mentalisation, les émotions en général et tout ce qui constitue l’expérience humaine.
                                </span>
                            </p>
                        </p>
                        <img src={require('../img/sarah.jpg')} className="bioImg" alt="" />
                    </div>
                </div>
                <div className="thanks">
                    <div className="thanksTitre">Remerciements</div>
                    <div className="thanksContainer">
                        <div className="thanksTxtDiv">
                            <span className="thanksTxt">Alexanne Lessard et Marie-Claude Séguin</span>
                            <span className="thanksSTxt">Pour les corrections</span>
                        </div>
                        <div className="thanksTxtDiv">
                            <span className="thanksTxt">Jean-Christophe Nolin</span>
                            <span className="thanksSTxt">Pour l'identité graphique</span>
                        </div>
                        <div className="thanksTxtDiv">
                            <span className="thanksTxt">Samuel Laverdure</span>
                            <span className="thanksSTxt">Pour le site web</span>
                        </div>
                        <div className="thanksTxtDiv">
                            <span className="thanksTxt">Laurie Bouvier, Alexis Viau et autres amis</span>
                            <span className="thanksSTxt">Pour les suggestions de films</span>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}