import { observer, inject } from "mobx-react";
import * as React from "react";
import '../review.css';
import { reviewStore } from "../store/reviewStore";
import { ReviewFrame } from "./reviewFrame";

interface Props {
    ReviewStore?: reviewStore;
}
interface State {
    loadedResources: boolean;
}

@inject("ReviewStore")
@observer
export class LatestReviews extends React.Component<Props, State>{
    state = {
        loadedResources: false
    };
    store = this.props.ReviewStore;

    componentDidMount = async () => {
        await this.store.getReviews(false);
        this.setState({
            loadedResources: true
        });

    }

    render() {
        if (this.state.loadedResources) {
            if (this.store.latestReviews) {
                return (
                    <React.Fragment>
                        {this.store.latestReviews.map((review, index) => (
                            <ReviewFrame key={index} Review={review} />
                        ))}
                    </React.Fragment>
                )
            }
        }
        return null;
    }
}