import { observer, inject } from "mobx-react";
import * as React from "react";
import { LatestReviews } from "../../review/components/latestReviews";
import { reviewStore } from "../../review/store/reviewStore";
import "../accueil.css"

interface Props {
    ReviewStore?: reviewStore;
}
@inject("ReviewStore")
@observer
export class Accueil extends React.Component<Props>{

    store = this.props.ReviewStore;

    render() {
        if (this.store) {
            return (
                <React.Fragment>
                    <div className="logoAccueil">
                        <img src={require('../img/logoDF.png')} className="logoImg" alt="" />
                    </div>
                    <div className="phraseIntro">
                        Comprendre&nbsp;l&rsquo;exp&eacute;rience&nbsp;d&rsquo;&ecirc;tre&nbsp;humain&nbsp;&agrave;&nbsp;travers&nbsp;nos&nbsp;films&nbsp;pr&eacute;f&eacute;r&eacute;s
                    </div>
                    <div className="vignettes">
                        <label className="latest">Les plus récents</label>
                        <div className="reviewsContainer">
                            <LatestReviews ReviewStore={this.store} />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    }
}