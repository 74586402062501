import { inject, observer } from "mobx-react";
import React from "react";
import { connexionStore } from "../store/connexionStore";
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import "../login.css";

interface Props {
  ConnexionStore?: connexionStore;
}

@inject("ConnexionStore")
@observer
export class Login extends React.Component<Props>{

  state = {
    isAuthentified: false,
    type: "password"
  };

  store = this.props.ConnexionStore;

  handleChange = (e: any) => { this.store.valueChanged(e.target) }

  handleClick = async (e: any) => {
    var res = await this.store.login();
    this.setState({
      isAuthentified: res
    });
  }

  handleEye = async () => {
    var newType = this.state.type === "password" ? "text" : "password";
    this.setState({
      type: newType
    })
  }

  render() {
    if (this.store) {
      if (this.state.isAuthentified) {
        return <Redirect to="/redaction" />
      }
      return (
        <div className="form">
          <div className="con">
            <header className="head-form">
              <h2>Connexion</h2>
            </header>
            <div className="field-set">
              <input className="form-input" type="text" placeholder="Courriel" data-key="Email"
                onChange={this.handleChange} />
              <div className="inputDiv">
                <input className="form-input-pwd" type={this.state.type} placeholder="Mot de passe" name="password" data-key="Password"
                  onChange={this.handleChange} />
                <FontAwesomeIcon icon={faEye} onClick={this.handleEye} />
              </div>
              <div>
                <button className="log-in" onClick={this.handleClick}> Connexion </button>
              </div>
            </div>
          </div>
        </div>

      );
    }
    return null;
  }
}