import { observer, inject } from "mobx-react";
import * as React from "react";
import { reviewStore } from "../store/reviewStore";
import { ReviewEditor } from "../../editor/reviewEditor";
import { connexionStore } from "../../connexion/store/connexionStore";
import '../redaction.css';
import { Dialog, DialogActions } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { publishErrors } from "./errors/errors";

interface Props {
    ReviewStore?: reviewStore;
}
@inject("ReviewStore")
@observer
export class Redaction extends React.Component<Props>{
    store = this.props.ReviewStore;
    types = ['image/png', 'image/jpeg'];
    questions: string[] = [];
    errors: string = "";
    conStore = connexionStore.getInstance();
    state = {
        isAuthentified: false,
        open: false,
        dialog: {},
        listQuestions: []
    };

    componentDidMount = async () => {
        var res = await this.conStore.checkIfAuth();
        this.setState({
            isAuthentified: res
        });
        this.store.resetRedaction();
    }

    handleNewFile = (e: any) => {
        let selected = e.target.files[0];
        var key = e.target.getAttribute("data-key");
        if (selected && this.types.includes(selected.type)) {
            this.store.ImageChanged(selected, key);
        }
        else {
            this.errors = "Veuillez sélectionner une image"
        }
    }

    handleChange = (e: any) => { this.store.valueChanged(e.target) }

    handlePublish = async (e: any) => {
        if (this.store.modifiableReview.Affiche != null && (this.store.modifiableReview.Titre != null && this.store.modifiableReview.Titre.trim().length !== 0)) {
            await this.store.publier();
            this.handleClose();
            this.store.resetRedaction();
            this.handleReset();
        }
    }

    handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
    }


    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleClickOpen = () => {
        var res = publishErrors(this.store.modifiableReview);
        this.setState({
            open: true,
            dialog: res
        });
    };
    handleDeleteQuestion = (e: any) => {
        var index = Number(e.target.getAttribute("data-key"));
        this.questions[index] = undefined;
        var div = document.getElementById("q" + index);
        div.parentNode.removeChild(div);
    }

    handleAddQuestionDiv = () => {
        this.questions.push("");
        this.setState({
            listQuestions: this.questions.map((q, index) =>
                <div id={"q" + index} key={"q" + index}>
                    <input type="text" className="questionInput" id={"input" + index} data-key={index} key={"input" + index} onChange={this.handleQuestionChange} />
                    <button onClick={this.handleDeleteQuestion} id={"delete" + index} data-key={index} key={"delete" + index}>Supprimer</button>
                </div>
            )
        })
    }
    handleQuestionChange = async (e: any) => {
        var index = Number(e.target.getAttribute("data-key"));
        var val = e.target.value;
        this.questions[index] = val;
        await this.store.QuestionsChanged(this.questions);
    }

    render() {
        if (this.store) {
            if (this.state.isAuthentified) {
                return (
                    <div className="containerRedaction">
                        <div className="redactionDiv">
                            <label>Titre :</label>
                            <input type="text" data-key="Titre" onChange={this.handleChange} />
                        </div>
                        <div className="redactionDiv">
                            <label>Sous-titre :</label>
                            <input type="text" data-key="SousTitre" onChange={this.handleChange} />
                        </div>
                        <div className="redactionDiv">
                            <label>Annee :</label>
                            <input type="text" data-key="Annee" onChange={this.handleChange} />
                        </div>
                        <div className="redactionDiv">
                            <label>Réalisateur :</label>
                            <input type="text" data-key="Realisateur" onChange={this.handleChange} />
                        </div>
                        <div className="redactionDiv">
                            <label>Phrase de début :</label>
                            <input type="text" id="phraseDeb" data-key="Phrase" onChange={this.handleChange} />
                        </div>
                        <div className="redactionDiv">
                            <ReviewEditor reviewStore={reviewStore.getInstance()} />
                        </div>
                        <div className="questionDiv" id="questionsID">
                            <button type="button" onClick={this.handleAddQuestionDiv}>Ajouter une question</button>
                            {this.state.listQuestions}
                        </div>
                        <div className="redactionDiv">
                            <label>Affiche :</label>
                            <input type="file" data-key="Affiche" onChange={this.handleNewFile} />
                        </div>
                        <div className="redactionDiv">
                            <label>Logo :</label>
                            <input type="file" data-key="Logo" onChange={this.handleNewFile} />
                        </div>
                        <div className="output redactionDiv">
                            {this.errors && (<div className="errors">{this.errors}</div>)}
                        </div>

                        <div className="redactionDiv">
                            <button type="button" onClick={this.handleClickOpen}>Publier</button>
                        </div>
                        <div>
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth="xs"
                            >
                                <DialogTitle>{"Publication de la critique"}</DialogTitle>
                                <DialogContent className="dialog">
                                    {this.state.dialog}
                                </DialogContent>
                                <DialogActions>
                                    <button onClick={this.handleClose} color="primary">
                                        Fermer
                                    </button>
                                    <button onClick={this.handlePublish} color="primary" autoFocus>
                                        Publier
                                    </button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                )
            }
            else {
                return "Déconnecté"
            }
        }
        return null;
    }
}