import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/auth';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_KEY,
  authDomain: process.env.REACT_APP_DOMAIN,
  databaseURL: process.env.REACT_APP_URL,
  projectId: process.env.REACT_APP_PRO,
  storageBucket: process.env.REACT_APP_STOBUC,
  messagingSenderId: process.env.REACT_APP_SENDER,
  appId: process.env.REACT_APP_APP,
  measurementId: process.env.REACT_APP_MEAS
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const fireAuth = firebase.auth();
export const fireStorageFunc = firebase.storage();
export const fireCloudFunc = firebase.firestore();
export const fireCloud = firebase.firestore;