import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { connexionStore } from '../connexion/store/connexionStore';

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  store = connexionStore.getInstance();

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      isAuthentified: false
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
      isAuthentified: this.store.isAuthentified
    });
  }

  handleLogout = async () => {
    await this.store.logout();
    this.setState({
      isAuthentified: this.store.isAuthentified
    });
    window.location.reload();
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navbar" light>
          <Container className="navContainer">
            <div className="mediaContainer">
              <div>
                <a href="https://www.facebook.com/projectionsintimes" target="_blank" rel="noopener noreferrer">
                  <img src={require('./img/mediaPaleFacebook.png')} className="mediaNav" alt="" />
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/projections.intimes/" target="_blank" rel="noopener noreferrer">
                  <img src={require('./img/mediaPaleInstagram.png')} className="mediaNav" alt="" />
                </a>
              </div>
            </div>
            <NavbarBrand tag={Link} to="/">
              <img src={require('./img/webName.png')} className="webName" alt="" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Accueil</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/archives">Archives</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/a_propos">À propos</NavLink>
                </NavItem>
                {this.store.isAuthentified && (
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/config">Privé</NavLink>
                  </NavItem>)
                }
                {this.store.isAuthentified && (
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/redaction">Rédaction</NavLink>
                  </NavItem>)
                }
                <NavItem></NavItem>
                {this.store.isAuthentified && (
                  <NavItem>
                    <NavLink tag={Link} onClick={this.handleLogout} className="text-dark" to="/connexion" >Déconnexion</NavLink>
                  </NavItem>
                )}
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
