import { observable } from 'mobx';
import { connexionService } from '../../connexion/service/connexionService';
import { fireCloud, fireCloudFunc, fireStorageFunc } from '../../Firebase'
import { Review } from "../model/review";

export class reviewService {

  @observable private static previousDoc = null;
  @observable private static ControlPanelDoc = null;
  @observable private static Reviews = [];
  @observable private static ReviewsAdmin = [];
  @observable private static isEmpty: boolean = false;
  @observable private static isNextEmpty: boolean = false;
  @observable private static isPreviousEmpty: boolean = false;

  static publier = async (review: Review) => {

    var storageRef = fireStorageFunc.ref();
    var files = [review.Affiche, review.Logo];
    var folders = ['ReviewImages/', 'LogoImages/']
    var Affiche = await reviewService.UploadImage(files[0], folders[0], storageRef);
    var Logo = await reviewService.UploadImage(files[1], folders[1], storageRef);
    var sousTitre = review.SousTitre ? review.SousTitre : null;
    await fireCloudFunc.collection("Reviews").doc(review.Titre.toLowerCase().split(' ').join('')).set({
      Titre: review.Titre,
      SousTitre: sousTitre,
      Texte: review.Texte,
      Questions: review.Questions,
      Annee: review.Annee,
      Realisateur: review.Realisateur,
      Phrase: review.Phrase,
      IsPrivate: true,
      PublishedDate: fireCloud.Timestamp.now().toDate(),
      Affiche: Affiche,
      Logo: Logo
    })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  static UploadImage = async (file, folder, storageRef) => {
    var metadata = {
      contentType: file.type
    };
    var uploadTask = storageRef.child(folder + file.name).put(file, metadata);
    var url = await uploadTask.then(function () {
      return uploadTask.snapshot.ref.getDownloadURL();
    });
    return url;

  }

  static getReview = async (MovieTitle: string) => {
    var isAuth = await connexionService.checkIfAuth();
    let resReview: Review;

    reviewService.Reviews.map(function (review) {
      var Equals = (review.Titre.toLowerCase().split(' ').join('') === MovieTitle);
      if (Equals) {
        resReview = review;
      }
    })
    if (!resReview) {
      var ReviewRef = fireCloudFunc.collection("Reviews");
      var res = await ReviewRef.doc(MovieTitle).get();
      resReview = res.data();
    }
    if (resReview && (resReview.IsPrivate && !isAuth)) { return null }
    return resReview;
  }

  static getLatestReviews = async () => {
    return reviewService.Reviews.slice(0, 3);
  }

  static getReviews = async () => {
    var ReviewRef = fireCloudFunc.collection("Reviews");
    var isAuth = await connexionService.checkIfAuth();
    const query = isAuth ?
      ReviewRef.orderBy("PublishedDate", "desc").limit(9) :
      ReviewRef.where("IsPrivate", "==", false).orderBy("PublishedDate", "desc").limit(9);

    if (!reviewService.isEmpty) {
      if (reviewService.previousDoc == null) {
        await query.get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              reviewService.Reviews.push(doc.data());
              reviewService.previousDoc = doc;
            });
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      }
      else {
        await query.startAfter(reviewService.previousDoc).get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              reviewService.Reviews.push(doc.data());
              reviewService.previousDoc = doc;
            });
            reviewService.isEmpty = querySnapshot.empty;
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      }
    }
    return reviewService.Reviews;
  }
  static setIsPublic = async (titre: string, revIsPrivate: boolean) => {
    await fireCloudFunc.collection("Reviews")
      .doc(titre.toLowerCase().split(' ').join(''))
      .update({
        IsPrivate: revIsPrivate ? false : true,
      })
      .then(function () {
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Mise à jour arrêté: ", error);
      });
  }
  static delReview = async (titre: string) => {
    await fireCloudFunc.collection("Reviews")
      .doc(titre.toLowerCase().split(' ').join('')).delete()
      .then(function () {
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Document non existant: ", error);
      });
  }

  static getListPrivate = async (selection: string) => {
    var ReviewRef = fireCloudFunc.collection("Reviews");
    var isAuth = await connexionService.checkIfAuth();
    const query = ReviewRef.where("IsPrivate", "==", true).orderBy("PublishedDate", "desc").limit(9)
    if (isAuth) {
      reviewService.ReviewsAdmin = [];
      if (reviewService.ControlPanelDoc == null) {
        await reviewService.getNullPrivate(query);
      }
      else {
        switch (selection) {
          case "Next":
            await reviewService.getNextPrivate(query);
            break;
          case "Back":
            await reviewService.getPreviousPrivate(query);
            break;
          case "Selected":
            await reviewService.getSelectedPrivate(query);
            break;
        }
      }
    }
    return reviewService.ReviewsAdmin;
  }

  private static getNextPrivate = async (query: any) => {
    if (!reviewService.isNextEmpty) {
      await query.startAfter(reviewService.ControlPanelDoc).get()
        .then(function (querySnapshot) {
          var newDoc = null;
          querySnapshot.forEach(function (doc) {
            reviewService.ReviewsAdmin.push(doc.data());
            newDoc = newDoc ? newDoc : doc;
          },
            reviewService.ControlPanelDoc = newDoc
          );
          reviewService.isNextEmpty = querySnapshot.empty;
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
      reviewService.isPreviousEmpty = false;
    }
  }

  private static getPreviousPrivate = async (query: any) => {
    if (!reviewService.isPreviousEmpty) {
      await query.endBefore(reviewService.ControlPanelDoc).get()
        .then(function (querySnapshot) {
          var newDoc = null;
          querySnapshot.forEach(function (doc) {
            reviewService.ReviewsAdmin.push(doc.data());
            newDoc = newDoc ? newDoc : doc;
          },
            reviewService.ControlPanelDoc = newDoc
          );
          reviewService.isPreviousEmpty = querySnapshot.empty;
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
      reviewService.isNextEmpty = false;
    }
  }

  private static getSelectedPrivate = async (query: any) => {
    await query.endAt(reviewService.ControlPanelDoc).get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          reviewService.ReviewsAdmin.push(doc.data());
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  private static getNullPrivate = async (query: any) => {
    await query.get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          reviewService.ReviewsAdmin.push(doc.data());
          reviewService.ControlPanelDoc = doc;
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }
}