import React from "react";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share";
import '../socialMediaButtons/socialMedia.css';
import { FacebookShareCount } from "react-share";

interface Props {
    url?: string;
}

export class SocialMediaButtons extends React.Component<Props> {
    url = this.props.url;

    render() {
        return (
            <React.Fragment>
                <div className="facebookWrapper">
                    <FacebookShareButton
                        url={this.url}
                        windowHeight={500}
                        windowWidth={500}
                        className="facebookShare"
                    //disabled={true}
                    >
                        <FacebookIcon size={36} round={true} />
                    </FacebookShareButton>
                    <FacebookShareCount url={this.url}>
                        {shareCount => <span className="FacebookCountWrapper">{shareCount}</span>}
                    </FacebookShareCount>
                </div>

                <div className="twitterWrapper">
                    <TwitterShareButton
                        url={this.url}
                        windowHeight={500}
                        windowWidth={500}
                        className="twitterShare"
                    >
                        <TwitterIcon size={36} round={true} />
                    </TwitterShareButton>
                </div>

            </React.Fragment>
        );
    }
}