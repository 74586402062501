import { authService } from './authService';

export class connexionService {

  static login = async (user: any) => {
    return await authService.signInWithEmailPwd(user.Email, user.Password);
  }

  static logout = async () => {
    await authService.signOut();

  }
  static checkIfAuth = async () => {
    return await authService.checkIfAuth();
  }
}