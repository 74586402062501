import { faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";
import React from "react";
import { Review } from "../../review/model/review";
import { reviewStore } from "../../review/store/reviewStore";

interface Props {
    ReviewStore?: reviewStore;
    review?: Review;
    OnChange?: any;
}
@inject("ReviewStore")
@observer
export class ReviewRow extends React.Component<Props>{
    store = this.props.ReviewStore;
    review = this.props.review;
    state = {
        loadedResources: false
    };

    handlePrivate = async () => {
        await this.store.setIsPublic(this.review.Titre, this.review.IsPrivate);
        this.props.OnChange();
    }

    handleDeletion = async () => {
        await this.store.delReview(this.review.Titre);
        this.props.OnChange();
    }

    componentDidMount = async () => {
        this.setState({
            loadedResources: true
        });
    }

    render() {
        if (this.state.loadedResources) {
            if (this.store) {
                return (
                    <React.Fragment>
                        <td>{this.review.Titre}</td>
                        <td>
                            <FontAwesomeIcon icon={faEyeSlash} onClick={this.handlePrivate} />
                            <FontAwesomeIcon icon={faTrash} onClick={this.handleDeletion} />
                        </td>
                    </React.Fragment>
                );
            }
        }
        return null
    }
}