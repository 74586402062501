import { observer, inject } from "mobx-react";
import * as React from "react";
import '../reviews.css';
import '../review.css';
import { reviewStore } from "../store/reviewStore";
import { ReviewFrame } from "./reviewFrame";

interface Props {
    ReviewStore?: reviewStore;
}
interface State {
    loadedResources: boolean;
}

@inject("ReviewStore")
@observer
export class Reviews extends React.Component<Props, State>{
    store = this.props.ReviewStore;
    listReviews;
    state = {
        loadedResources: false
    };

    mapReviews = async () => {
        const listReviews = this.store.Reviews.map((review, index) =>
            <div className="reviewArchive" key={index}>
                <ReviewFrame key={index} Review={review} />
            </div>
        );
        return (
            listReviews
        );
    }

    handleClick = async (e: any) => {
        await this.store.getReviews(true);
        this.listReviews = await this.mapReviews();
        this.setState({
            loadedResources: true
        });
    }

    componentDidMount = async () => {
        await this.store.getReviews(false);
        this.listReviews = await this.mapReviews();
        this.setState({
            loadedResources: true
        });

    }

    render() {
        if (this.state.loadedResources) {
            if (this.store.Reviews) {
                return (
                    <div className="archives">
                        <div className="archivesContainer">
                            {this.listReviews}
                        </div>
                        <div className="seeMoreContainer">
                            <button type="button" className="seeMore" onClick={this.handleClick}>Voir plus...</button>
                        </div>
                    </div>
                )
            }
        }
        return null;
    }
}