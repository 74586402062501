import * as React from "react";
import { Component } from "react";
import { observer, Provider } from "mobx-react";
import "./custom.css";

interface State {
    loadedResources: boolean;
}
interface Props { }
@observer
export class Construction extends Component<Props, State> {
    state = {
        loadedResources: false,
    };

    componentDidMount() {
        this.setState({
            loadedResources: true
        });
    }

    render() {
        if (this.state.loadedResources) {
            return (
                <React.Fragment>
                    <div className="construction">
                        <span>En construction</span>
                        <span>Lancement du site le 11 janvier 2021</span>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    }
}