import { action, observable } from "mobx";
import { User } from "../model/user";
import { connexionService } from "../service/connexionService";

export class connexionStore {
    private user: User = new User();
    @observable errorMessage: string = "";
    @observable isAuthentified: boolean;

    private static instance: connexionStore;

    static getInstance = () => {
        if (!connexionStore.instance) {
            connexionStore.instance = new connexionStore();
        }
        return connexionStore.instance;
    };

    @action login = async () => {
        var user =
        {
            Email: this.user.Email,
            Password: this.user.Password
        }
        await connexionService.login(user);
        this.isAuthentified = await this.checkIfAuth();
        return this.isAuthentified;
    }
    @action logout = async () => {
        await connexionService.logout();
        this.isAuthentified = await this.checkIfAuth();
        return this.isAuthentified;
    }

    checkIfAuth = async () => {
        return await connexionService.checkIfAuth();
    }

    @action valueChanged = (e: any) => {
        const key = e.getAttribute("data-key");
        if (this.hasKey(this.user, key)) {
            this.user[key] = e.value;
        }
    }

    hasKey<O>(obj: O, key: keyof any): key is keyof O {
        return key in obj
    }
}