import { action, observable } from "mobx";
import { Review } from "../model/review";
import { reviewService } from "../service/reviewService"

export class reviewStore {
  @observable modifiableReview = new Review();
  @observable singleReview = new Review();
  @observable latestReviews;
  @observable Reviews;
  @observable selectedReview;
  @observable errors: string[]

  private static instance: reviewStore;

  static getInstance = () => {
    if (!reviewStore.instance) {
      reviewStore.instance = new reviewStore();
    }
    return reviewStore.instance;
  };

  @action setSelectedReview = async (review: Review) => {
    this.selectedReview = review;
  }

  @action handleImageChange = (image) => {
    this.modifiableReview.Affiche = image;
  }

  @action publier = async () => {
    this.modifiableReview.Questions = this.modifiableReview.Questions.filter(function (e) {
      return e !== undefined;
    });
    await reviewService.publier(this.modifiableReview);
  }

  @action getReview = async (MovieTitle: string) => {
    var res = await reviewService.getReview(MovieTitle.toLowerCase());
    this.selectedReview = res;
  }

  @action getReviews = async (isOnClick: boolean) => {
    if (!this.Reviews || isOnClick) {
      var res = await reviewService.getReviews();
      this.Reviews = res;
    }
    if (!this.latestReviews) {
      this.latestReviews = await reviewService.getLatestReviews();
    }
  }
  @action setIsPublic = async (titre: string, isPrivate: boolean) => {
    await reviewService.setIsPublic(titre, isPrivate);
  }

  @action delReview = async (titre: string) => {
    await reviewService.delReview(titre);
  }

  @action getListPrivate = async (selection: string) => {
    var privateReviews = await reviewService.getListPrivate(selection);
    return privateReviews;
  }

  saveText = (textContent) => {
    this.modifiableReview.Texte = textContent;
  }
  resetRedaction = () => {
    this.modifiableReview = new Review();
  }

  @action valueChanged = (e: any) => {
    const key = e.getAttribute("data-key");
    if (this.hasKey(this.modifiableReview, key)) {
      this.modifiableReview[key] = e.value;
    }
  }

  @action ImageChanged = (Image, key) => {
    if (this.hasKey(this.modifiableReview, key)) {
      this.modifiableReview[key] = Image;
    }
  }
  @action QuestionsChanged = (questions) => {
    this.modifiableReview.Questions = questions;
  }

  hasKey<O>(obj: O, key: keyof any): key is keyof O {
    return key in obj
  }
}