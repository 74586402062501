import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Review } from "../../model/review";

export function publishErrors(review: Review) {
    var attrList = ["Titre : ", "Annee : ", "Réalisateur : ", "Texte : ", "Affiche : ", "Logo : "];
    var attrCheckList = [review.Titre, review.Annee, review.Realisateur, review.Texte, review.Affiche, review.Logo];
    var html = attrCheckList.map((value, index) => {
        return !value ?
            <React.Fragment key={"fragment_" + index}>
                <div className="grid-container" key={"div_" + index}>
                    <label key={"label_" + index}>{attrList[index]}</label>
                    <FontAwesomeIcon className="grid-item" icon={faTimesCircle} key={"icon_" + index} />
                </div>
            </React.Fragment> :
            <React.Fragment key={"fragment_" + index}>
                <div className="grid-container" key={"div_" + index}>
                    <label className="grid-item" key={"label_" + index}>{attrList[index]}</label>
                    <FontAwesomeIcon className="grid-item" icon={faCheck} key={"icon_" + index} />
                </div>
            </React.Fragment>

    });
    return html;
}