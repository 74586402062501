import { inject, observer } from "mobx-react";
import React from "react";
import { connexionStore } from "../../connexion/store/connexionStore";
import { reviewStore } from "../../review/store/reviewStore";
import { ReviewRow } from "./reviewRow";
import "../controlPanel.css";

interface Props {
    ReviewStore?: reviewStore;
}
@inject("ReviewStore")
@observer
export class ControlPanel extends React.Component<Props>{
    store = this.props.ReviewStore;
    conStore = connexionStore.getInstance();
    listReviews;
    state = {
        isAuthentified: false,
        loadedResources: false
    };

    onChange = async () => {
        this.setState({
            loadedResources: false
        });
        this.listReviews = await this.mapReviews("Selected");
        this.setState({
            loadedResources: true
        });
    }

    mapReviews = async (selection: string) => {
        var privateRev = await this.store.getListPrivate(selection);
        const listReviews = privateRev.map((review, index) =>
            <tr className="reviewRow" key={index}>
                <ReviewRow ReviewStore={this.store} review={review} OnChange={this.onChange} />
            </tr>
        );
        return (
            listReviews
        );
    }

    componentDidMount = async () => {
        var res = await this.conStore.checkIfAuth();
        this.setState({
            isAuthentified: res
        });
        if (this.state.isAuthentified) {
            this.listReviews = await this.mapReviews("Selected");
        }
        this.setState({
            loadedResources: true
        });
    }

    render() {
        if (this.state.loadedResources) {
            if (this.state.isAuthentified) {
                if (this.store) {
                    return (
                        <div>
                            <table className="privateReview">
                                <tbody>
                                    <tr>
                                        <th>Titre</th>
                                        <th>Actions</th>
                                    </tr>
                                    {this.listReviews}
                                </tbody>
                            </table>
                            <div className="controlsDiv">
                                <button className="previousReviews">&laquo;</button>
                                <button className="nextReviews">&raquo;</button>
                            </div>
                        </div>
                    );
                }
            }
        }
        return ("Déconnecté");
    }
}