export class Review {
    Id?: string
    Titre?: string
    SousTitre?: string
    Affiche?: any
    Logo?: any
    Texte?: any
    Questions?: any[] = []
    Annee?: string
    Realisateur?: string
    Phrase?: string
    IsPrivate?: boolean
    PublishedDate?: string
}