import * as React from "react";
import { Component } from "react";
import { observer, Provider } from "mobx-react";
import { reviewStore } from "./review/store/reviewStore";
import { Accueil } from "./accueil/components/accueil";

interface State {
  loadedResources: boolean;
}
interface Props { }
@observer
export class AccueilProvider extends Component<Props, State> {
  state = {
    loadedResources: false,
    ReviewStore: reviewStore.getInstance()
  };

  componentDidMount() {
    this.setState({
      loadedResources: true
    });
  }

  render() {
    if (this.state.loadedResources) {
      return (
        <Provider ReviewStore={this.state.ReviewStore}>
          <Accueil />
        </Provider>
      );
    }
    return null;
  }
}