import * as React from "react";
import { Component } from "react";
import { observer, Provider } from "mobx-react";
import { reviewStore } from "../../review/store/reviewStore";
import { ControlPanel } from "../component/controlPanel";

interface State {
    loadedResources: boolean;
}
interface Props { }
@observer
export class ControlPanelProvider extends Component<Props, State> {
    state = {
        loadedResources: false
    };

    componentDidMount = async () => {
        this.setState({
            loadedResources: true
        });
    }

    render() {
        if (this.state.loadedResources) {
            return (
                <Provider ReviewStore={reviewStore.getInstance()}>
                    <ControlPanel />
                </Provider>
            );
        }
        return null;
    }
}