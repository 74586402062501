import React from "react";
import { Helmet } from "react-helmet";
import { Review } from "../model/review";

interface Props {
    review?: Review;
    url?: string;
}

export class HelmetMetaData extends React.Component<Props> {
    currentUrl = this.props.url;
    title = this.props.review.Titre;
    image = this.props.review.Logo;
    render() {
        return (
            <Helmet>
                <title>{"Les Projections Intimes - " + this.title}</title>
                <meta name="description" content={this.props.review.Phrase} />
                <meta property="og:title" content={"Les Projections Intimes - " + this.title} />
                <meta property="og:url" content={this.currentUrl} />
                <meta property="og:type" content="critique" />
                <meta property="og:description" content={this.props.review.Phrase} />
                <meta property="og:image" content={this.props.review.Logo} />
                <meta property="og:image:width" content="718" />
                <meta property="og:image:height" content="718" />
            </Helmet>
        );
    }
}