import React from "react";
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './editor.css';
import { reviewStore } from "../review/store/reviewStore";
import { inject, observer } from "mobx-react";

interface Props {
	reviewStore?: reviewStore
}

@inject("ReviewStore")
@observer
export class ReviewEditor extends React.Component<Props> {
	state = { editorState: EditorState.createEmpty() };
	store = this.props.reviewStore;

	onChange = (editorState) => {
		const contentState = convertToRaw(editorState.getCurrentContent());
		this.store.saveText(JSON.stringify(contentState));
		this.setState({ editorState });
	}

	toggleBlockType = (blockType) => {
		this.onChange(
			RichUtils.toggleBlockType(
				this.state.editorState,
				blockType
			));
	}

	toggleInlineStyle = (inlineStyle) => {
		this.onChange(
			RichUtils.toggleInlineStyle(
				this.state.editorState,
				inlineStyle
			)
		);
	}

	render() {
		const { editorState } = this.state;
		let className = 'RichEditor-editor';
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				className += ' RichEditor-hidePlaceholder';
			}
		}

		return (
			<div className="RichEditor-root">
				<InlineStyleControls
					editorState={editorState}
					onToggle={this.toggleInlineStyle}
				/>
				<div className={className}>
					<Editor
						customStyleMap={styleMap}
						editorState={editorState}
						onChange={this.onChange}
						placeholder="Votre prochaine critique..."
						ref="editor"
					/>
				</div>
			</div>
		);
	}
}


const styleMap = {
	CODE: {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2,
	},
};

const StyleButton = ({ style, onToggle, active, label }) => {
	const onMouseDown = (e) => {
		e.preventDefault();
		onToggle(style);
	}

	const className = !active ? 'RichEditor-styleButton' : 'RichEditor-styleButton RichEditor-activeButton'

	return (
		<span className={className} onMouseDown={onMouseDown}>
			{label}
		</span>
	);

}

const INLINE_STYLES = [
	{ label: 'Bold', style: 'BOLD' },
	{ label: 'Italic', style: 'ITALIC' },
	{ label: 'Underline', style: 'UNDERLINE' },
];

const InlineStyleControls = ({ editorState, onToggle }) => {
	var currentStyle = editorState.getCurrentInlineStyle();
	return (
		<div className="RichEditor-controls">
			{INLINE_STYLES.map(type =>
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={onToggle}
					style={type.style}
				/>
			)}
		</div>
	);
}