import { observer, inject } from "mobx-react";
import * as React from "react";
import { reviewStore } from "../store/reviewStore";
import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import '../singleReview.css';
import { SocialMediaButtons } from "../socialMediaButtons/socialMediaButtons";
import { HelmetMetaData } from "../socialMediaButtons/HelmetMetaData";

interface Props {
  ReviewStore?: reviewStore;
}

@inject("ReviewStore")
@observer
export class SingleReview extends React.Component<Props>{

  store = this.props.ReviewStore;
  review = this.store.selectedReview;
  texteHtml;
  datePub;
  url: string;

  state = {
    loadedResources: false
  };

  componentDidMount = async () => {
    await this.loadingLogic();
  }

  loadingLogic = async () => {
    var pos = window.location.href.indexOf("?");

    if (pos !== -1) {
      this.url = window.location.href.substr(0, pos);
      document.location.href = this.url;
    }
    else {
      this.url = window.location.href;
    }

    var movieTitlePosition = this.url.lastIndexOf("/") + 1;
    var movieTitle = this.url.substr(movieTitlePosition, this.url.length)

    if (!this.review || movieTitle !== this.review.Titre) {
      await this.store.getReview(movieTitle);
      this.review = this.store.selectedReview;
    }

    if (this.review) {
      this.datePub = this.review.PublishedDate.toDate().toLocaleDateString('en-GB');
      var jsonParsing = JSON.parse(this.review.Texte);
      var content = convertFromRaw(jsonParsing);
      this.texteHtml = convertToHTML(content);
      this.setState({
        loadedResources: true
      });
    }
  }

  render() {
    if (this.state.loadedResources) {
      if (this.review) {
        return (
          <React.Fragment>
            <div className="overlay">
              <div className="TSReview">
                <span className="title">{this.review.Titre}</span>
                {this.review.SousTitre && (
                  <span className="sousTitre">{this.review.SousTitre}</span>
                )}
              </div>
              <div className="realisateur">
                <label>Réalisé par {this.review.Realisateur} ({this.review.Annee})</label>
              </div>
              <div className="review" dangerouslySetInnerHTML={{
                __html: this.texteHtml
              }}>
              </div>
              {this.review.Questions && (<div className="questionsList">
                <label className="questionTitre">Questions de réflexion</label>
                {this.review.Questions.map((q, index) =>
                  <div className="questionDiv" key={"qDiv" + index}>
                    <span key={"q" + index}>{index + 1 + ". " + q}</span>
                  </div>
                )}
              </div>)}
              <div className="logo">
                <img src={this.review.Logo} alt="" />
              </div>
              <div className="datePub">
                <label>{this.datePub}</label>
              </div>
            </div>
            <div className="socialMediaButtons">
              <HelmetMetaData review={this.review} url={this.url}></HelmetMetaData>
              <SocialMediaButtons url={this.url} />
            </div>
          </React.Fragment>
        )
      }
    }
    return "La critique sur le film recherché n'existe pas ";
  }
}